















import { defineComponent } from "@vue/composition-api"
import Card from "../cards/Card.vue"

export default defineComponent({
  components: {
    Card,
  },
})
