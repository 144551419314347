












































































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import AvatarList from "@/components/AvatarList.vue"
import { CardMenu, CardMenuItem } from "@/components/CardMenu"
import I18nFormattedDateTime from "@/components/i18n/I18nFormattedDateTime.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Icon from "@/components/Icon.vue"
import Loading from "@/components/Loading.vue"
import { TypedComponentProps } from "@/vue-props-types"
import { Props } from "./types"
import { goToPath, goToRoute } from "@/router"
import RouteName from "@/constants/RouteName"
import DateTime from "@/models/DateTime"
import Card from "@/components/cards/Card.vue"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { Meeting, MeetingStructure } from "@/models/Meeting"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"
import { hintContainer, HintType } from "@/containers/HintContainer"
import { meetingOrder } from "@/utilities/Order"
import User from "@/models/User"
import IconButton from "@/components/buttons/IconButton.vue"
import LinkGet from "@/components/LinkGet.vue"
import MeetingListItem from "./MeetingListItem.vue"
import DefaultButton from "@/components/buttons/DefaultButton.vue"

const propsOptions = {
  id: {
    type: String,
    default: "",
  },
}

interface State {
  meetingStructure: MeetingStructure | null
  isLoadingMeetings: boolean
  isMeAsGuest: boolean
  isSettingMeetingToState: boolean
  numAfterNextMeetingItemsToShow: number
}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: propsOptions,
    components: {
      AvatarList,
      CardMenu,
      CardMenuItem,
      I18nFormattedDateTime,
      I18nFormattedMessage,
      Icon,
      Loading,
      Card,
      LinkGet,
      MeetingListItem,
      DefaultButton,
      IconButton,
    },
    setup(props) {
      const { getContext, getMembers } = teamsContextContainer.useContainer()
      const { showHint } = hintContainer.useContainer()
      const {
        state: meetingState,
        getMeetingsAsync,
        getAndSetMeetingDetails,
      } = meetingContainer.useContainer()
      const { getProjectAsync } = projectsContainer.useContainer()
      const state = reactive<State>({
        meetingStructure:
          meetingState.meetings.value?.find(m => m.id === props.id) || null,
        isLoadingMeetings: meetingState.isLoadingMeetings.value,
        isMeAsGuest: false,
        isSettingMeetingToState: false,
        numAfterNextMeetingItemsToShow: 5,
      })

      const plannedMeetingItemsByDateAsc = computed<Array<Meeting>>(() => {
        if (!state.meetingStructure?.meetings) return []
        const now = new DateTime()
        return state.meetingStructure?.meetings
          .slice()
          .filter(i => i.endTime.isAfter(now, "minutes"))
          .sort(meetingOrder)
          .reverse()
      })

      const nextMeetingItem = computed<Meeting | null>(() => {
        if (plannedMeetingItemsByDateAsc.value.length < 1) return null
        return plannedMeetingItemsByDateAsc.value[0]
      })

      const afterNextMeetingItems = computed<Array<Meeting>>(() =>
        plannedMeetingItemsByDateAsc.value.slice(1)
      )

      const showingAfterNextMeetingItems = computed<Array<Meeting>>(() =>
        afterNextMeetingItems.value.slice(
          0,
          state.numAfterNextMeetingItemsToShow
        )
      )

      const hasMoreAfterNextMeetingItems = computed<boolean>(
        () =>
          showingAfterNextMeetingItems.value.length <
          afterNextMeetingItems.value.length
      )

      const previousMeetingItemsByDateDesc = computed<Array<Meeting>>(() => {
        if (!state.meetingStructure?.meetings) return []
        const now = new DateTime()
        return state.meetingStructure?.meetings
          .filter(i => i.endTime.isSameOrBefore(now, "minutes"))
          .sort(meetingOrder)
      })

      const previousMeetingItem = computed<Meeting | null>(
        () => previousMeetingItemsByDateDesc.value[0] || null
      )

      const setMeetingToState = async (currentId: string) => {
        state.isSettingMeetingToState = true
        const context = await getContext()
        const meetings = await getMeetingsAsync(context.entityId)
        const me = (await getMembers()).find(
          m => m.userId === context.userObjectId
        )
        const project = await getProjectAsync(context.entityId)
        state.isMeAsGuest = new User(me).roles?.includes("guest") ?? false
        const currentMeeting = meetings.find(m => m.id === currentId)
        if (!currentMeeting) {
          if (meetings.length === 0) {
            state.isLoadingMeetings = false
            state.isSettingMeetingToState = false
            return
          } else {
            goToRoute({
              name: RouteName.MeetingSeries,
              params: { id: meetings[0]?.id },
            })
            state.isSettingMeetingToState = false
            return
          }
        }
        state.isLoadingMeetings = false
        state.meetingStructure = currentMeeting
        const loadedMeetings = (
          await getAndSetMeetingDetails(project.siteId, context.entityId)
        ).find(m => m.id === currentId)
        if (loadedMeetings) state.meetingStructure = loadedMeetings
        state.isSettingMeetingToState = false
      }

      const goToMeetingInfo = () => {
        if (state.meetingStructure)
          goToPath(`/meetingInfo/${state.meetingStructure.id}`)
      }

      const goToNotificationSettings = () => {
        if (state.meetingStructure)
          goToRoute({
            name: RouteName.NotificationSettings,
            params: {
              id: state.meetingStructure.id,
            },
          })
      }

      const goToMemoTemplateSettings = () => {
        if (state.meetingStructure) {
          goToRoute({
            name: RouteName.MemoTemplateSettings,
            params: { id: state.meetingStructure.id },
          })
        }
      }

      const goToExternalLinkSettings = () => {
        if (state.meetingStructure) {
          goToRoute({
            name: RouteName.ExternalLinkSettings,
            params: {
              id: state.meetingStructure.id,
            },
          })
        }
      }

      const goToNewMeetingCreation = () => {
        if (state.meetingStructure)
          goToRoute({
            name: RouteName.NewMeetingCreation,
            params: { id: state.meetingStructure.id },
          })
      }

      const goToMeetingCreationFromOutlook = () => {
        if (state.meetingStructure)
          goToRoute({
            name: RouteName.MeetingCreationFromOutlook,
            params: { id: state.meetingStructure.id },
          })
      }

      const goToPastMemoList = () => {
        if (state.meetingStructure)
          goToRoute({
            name: RouteName.PastMemoList,
            params: { id: state.meetingStructure.id },
          })
      }

      const goToConfirmApproves = () => {
        if (state.meetingStructure)
          goToRoute({
            name: RouteName.ConfirmApproves,
            params: { id: state.meetingStructure.id },
          })
      }

      const showMoreAfterNextMeetingItems = () => {
        state.numAfterNextMeetingItemsToShow = Math.min(
          state.numAfterNextMeetingItemsToShow + 5,
          afterNextMeetingItems.value.length
        )
      }

      watch(
        () => props.id,
        async newValue => {
          if (newValue) {
            await setMeetingToState(newValue)
          } else {
            state.meetingStructure = null
          }
        }
      )

      watch(
        () => meetingState.meetings.value,
        async () => {
          await setMeetingToState(props.id)
        }
      )

      onMounted(async () => {
        await setMeetingToState(props.id)
      })

      const showUpdates = () => {
        showHint(HintType.Updates)
      }

      return {
        state,
        nextMeetingItem,
        showingAfterNextMeetingItems,
        hasMoreAfterNextMeetingItems,
        previousMeetingItem,
        goToMeetingInfo,
        goToNotificationSettings,
        goToMemoTemplateSettings,
        goToExternalLinkSettings,
        goToNewMeetingCreation,
        goToMeetingCreationFromOutlook,
        goToPastMemoList,
        goToConfirmApproves,
        showMoreAfterNextMeetingItems,
        showUpdates,
      }
    },
  }
)
