








import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  setup(_props, { emit }) {
    const onClick = () => {
      emit("click")
    }

    return { onClick }
  },
})
